import 'core-js/stable';
import 'mutationobserver-shim';
import Vue from 'vue';
import './plugins/vue-axios';
import App from './App.vue';
import router from './router';
import Permission from '@/js/permission';

import './plugins/bootstrap-vue';
import './plugins/font-awesome';
import './plugins/vee-validate';
import './plugins/vue-sweetalert';
import './plugins/vue-select';
import './plugins/cooper';
import i18n from './plugins/vue-i18n';
import './scss/style.scss';
import 'select2/dist/js/select2';
import '../../cooper/src/scss/components/cooper/_select2.scss';

Vue.config.productionTip = false;
Vue.prototype.$permission = Permission;

let vue = new Vue({
    router,
    i18n,
    render: h => h(App)
}).$mount('#app');

export default vue;
