import Vue from 'vue';
import VueI18n from 'vue-i18n';
import i18nData from './vue-i18nData';

Vue.use(VueI18n);

let locale = localStorage.getItem('language');

export default new VueI18n({
    locale: locale,
    messages: i18nData
});