import Vue from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';

import {
    faCube,
    faHome,
    faAngleDown,
    faAngleLeft,
    faBars,
    faClone,
    faDollarSign,
    faEdit,
    faPencilAlt,
    faExchangeAlt,
    faEye,
    faList,
    faLock,
    faMapMarkedAlt,
    faPlus,
    faPlusCircle,
    faQuestionCircle,
    faSave,
    faSearch,
    faSync,
    faSignOutAlt,
    faStoreAlt,
    faToggleOff,
    faToggleOn,
    faTrashAlt,
    faUser,
    faUsers,
    faUserCircle,
    faBan,
    faPrint,
    faAngleRight,
    faAngleDoubleRight,
    faAngleDoubleLeft,
    faCheck,
    faClock,
    faCaretRight,
    faCaretLeft,
    faExclamationTriangle,
    faLeaf,
    faInbox,
    faPaperclip,
    faFile,
    faDownload,
    faDivide,
    faImage,
    faLink,
    faUnlink,
    faTimes,
    faVenus,
    faMars,
    faVenusMars,
    faSeedling,
    faVial,
    faFillDrip,
    faRedoAlt,
    faCommentAlt,
    faFileExcel,
    faTh,
    faCalendarDay,
    faWrench,
    faPaperPlane, faTrash, faShoppingCart, faFilePdf
} from '@fortawesome/free-solid-svg-icons';

library.add(faCube, faHome, faAngleDown, faAngleLeft, faBars, faClone, faDollarSign, faEdit, faPencilAlt, faExchangeAlt,
    faPlus, faPlusCircle, faQuestionCircle, faSave, faSearch, faSync, faSignOutAlt, faStoreAlt, faToggleOff, faCaretRight, faCaretLeft,
    faToggleOn, faTrashAlt, faUser, faUsers, faUserCircle, faBan, faPrint, faAngleRight, faAngleDoubleRight, faAngleDoubleLeft,
    faCheck, faClock, faExclamationTriangle, faLeaf, faInbox, faPaperclip, faFile, faDownload, faDivide, faImage, faLink, faTimes,
    faVenus, faMars, faUnlink, faVenusMars, faSeedling, faVial, faFillDrip, faRedoAlt, faCommentAlt, faFileExcel, faTh, faCalendarDay,
    faWrench, faEye, faList, faLock, faMapMarkedAlt, faPaperPlane, faTrash, faShoppingCart, faFilePdf);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);