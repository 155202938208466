export default {
    en: {
        // General
        home: 'Home',
        invoices: 'Invoices',
        invoice: 'Invoice',
        purchaseOrders: 'Purchase Orders',
        lastUpdate: 'Last update',
        supplierPortal: 'Supplier portal',
        notices: 'Notices',
        notNotices: 'No notices',
        register: 'Register',
        noRecords: 'No records',
        noMatches: 'No matches',
        all: 'All',
        detail: 'Detail',
        select: 'Select',
        edit: 'Edit',
        back: 'Back',
        cancel: 'Cancel',
        send: 'Send',
        update: 'Update',
        registration: 'registration',
        data: 'data',
        contact: 'Contact',
        buyer: 'Purchaser',
        total: 'Total',
        accountContact: 'Account contact',
        account: 'Account',
        comments: 'Comments',
        reason: 'Reason',
        searching: 'Searching',
        attention: 'Attention',
        yes: 'Yes',
        notes: 'Notes',
        noRecord: 'No record',
        validate: 'Validate',
        size: 'Size',
        download: 'Download',

        // status
        toVerify: 'To verify',
        toUpdate: 'To update',
        rejected: 'Rejected',
        toValidate: 'To validate',
        inProcess: 'In process',
        paid: 'Paid',
        cancelled: 'Cancelled',
        done: 'Done',
        active: 'Active',
        inactive: 'Inactive',

        // Invoice
        serial: 'Serial',
        folio: 'Folio',
        invoiceDate: 'Invoice date',
        invoiceNumber: 'Invoice number',
        purchaseOrder: 'Purchase order',
        amount: 'Amount',
        currency: 'Currency',
        status: 'Status',
        paymentWay: 'Payment way',
        paymentMethod: 'Payment method',
        useCFDI: 'Use CFDI',
        voucherType: 'Voucher type',
        uuid: 'UUID',
        issuingTaxId: 'Issuing tax ID',
        issuingName: 'Issuing name',
        recipientTaxId: 'Recipient tax ID',
        recipientName: 'Recipient name',
        estimatedPaymentDate: 'Estimated payment date',

        // Data Table
        recordDisplayed: 'Showing START to END of TOTAL_ROWS record',
        dates: 'Dates',
        actions: 'Actions',
        show: 'Show',
        records: 'records',
        created: 'Created',

        // Login
        username: 'Username',
        password: 'Password',
        login: 'Log in',
        logOut: 'Log out',
        notHaveAnAccount: 'Don\'t have an account?',
        haveAnAccount: 'Already have an account?',
        signUp: 'Sign up',

        // Supplier
        foreignSupplier: 'Foreign supplier',
        tradeName: 'Trade name',
        legalName: 'Legal name',
        capitalRegime: 'Capital regime',
        taxId: 'Tax ID',
        industry: 'Industry',
        website: 'Website',
        bankInformation: 'Bank information',
        constitutiveAct: 'Constitutive act', // REVISAR ...
        taxSituation: 'Tax situation', // REVISAR ...
        bankCover: 'Bank cover',
        repse: 'REPSE',

        // Address
        street: 'Street',
        intNumber: 'Internal number',
        extNumber: 'External number',
        neighborhood: 'Neighborhood',
        borough: 'Borough',
        city: 'City',
        state: 'State',
        country: 'Country',
        zipcode: 'Zipcode',

        // Contact
        name: 'Name',
        jobPosition: 'Job position',
        jobArea: 'Job area',
        phone: 'Phone',
        extension: 'Phone extension',
        cellPhone: 'Mobile',
        email: 'Email',

        // Error messages
        requiredField: 'Required field',
        invalidEmail: 'Enter a valid email address',
        incorrectUsernamePassword: 'Incorrect username and/or password',
        rejectedMessage: 'The registration was rejected, please update the data and send it again.',
        noResultsFound: 'No results found',
        msgSendRegistration: 'Are you sure you want to ACTION the registration?',
        selectFile: 'Please select a file',
        taxIdDifferent: 'The issuer\'s tax ID is different from the supplier tax ID',
        invalidTaxId: 'Invalid recipient tax ID',
        onlyCurrentMonth: 'Only current month invoices are allowed',
        differentPaymentMethod: 'Invoices with PPD (Payment in installments or deferred payment) are only accepted with payment method 099 (To be defined).',
        invalidVoucherType: 'The field "Tipo de comprobante" is invalid',
        invalidFileFormat: 'The file format is not valid',
        taxIdRegistered: 'The tax ID is already registered with another supplier.',
        emailRegistered: 'The email address is already registered with another provider',
        tokenRevoked: 'The token was revoked',
        tokenRedeemed: 'The token was previously redeemed',
        NoTokenExist: 'The token does not exist',
        invoiceRegistered: 'The invoice is already registered.',
        notValueValid: 'Enter a valid value',
        exchangeRate: 'Exchange rate',
        inactiveAccount: 'Account Inactive',

        // Success messages
        supplierSentSuccess: 'The registration form was sent correctly',
        updateSuccess: 'Successfully updated',
        invoiceSent: 'Invoice sent',

        // Invoice alerts messages
        pendingPurchaseOrderCode: 'It is mandatory to validate the purchase order',
        validPurchaseOrderCode: 'Valid purchase order',
        invalidPurchaseOrderCode: 'Invalid purchase order',
        purchaseOrderInvoicedAlert: 'The total amount of the purchase order is already invoiced,it is not allowed to add more invoices.',
        purchaseOrderCurrencyPart1Alert: 'The currency of the purchase order',
        purchaseOrderCurrencyPart2Alert: 'is different from the invoice currency',
        amountExceeded: 'The current invoiced amount plus the amount of this invoice, exceeds the amount of the purchase order.',

        // Invoice labels
        purchaseOrderAmountLabel: 'Purchase order amount',
        amountInvoicedLabel: 'Invoiced amount',
        invoiceAmountLabel: 'This invoice amount',
        requiresXmlFile: 'XML required',
        registerInvoice: 'Register invoice',

        // Alerts
        msgActionInvoice: 'Are you sure you want to ACTION the invoice?',

        // Constants
        DEFAULT_ERROR_MESSAGE: 'An error has occurred. Please contact support.',
        FORBIDDEN_ERROR_MESSAGE: 'You are not authorized to perform this action.'
    },
    es: {
        // General
        home: 'Inicio',
        invoices: 'Facturas',
        invoice: 'Factura',
        purchaseOrders: 'Órdenes de compra',
        lastUpdate: 'Última actualización',
        supplierPortal: 'Portal de proveedores',
        notices: 'Avisos',
        notNotices: 'No hay avisos por el momento',
        register: 'Registrar',
        noRecords: 'Sin registros',
        noMatches: 'Sin coincidencias',
        all: 'Todas',
        detail: 'Detalle',
        select: 'Seleccionar',
        edit: 'Editar',
        back: 'Regresar',
        cancel: 'Cancelar',
        send: 'Enviar',
        update: 'Actualizar',
        registration: 'registro',
        data: 'datos',
        contact: 'Contacto',
        buyer: 'Comprador',
        total: 'Total',
        accountContact: 'Contacto de la cuenta',
        account: 'Cuenta',
        comments: 'Observaciones',
        reason: 'Motivo',
        searching: 'Buscando',
        attention: 'Atención',
        yes: 'Sí',
        notes: 'Notas',
        noRecord: 'Sin registro',
        validate: 'Validar',
        size: 'Tamaño',
        download: 'Descargar',

        // status
        toVerify: 'Por verificar',
        toUpdate: 'Por actualizar',
        rejected: 'Rechazada',
        toValidate: 'Por validar',
        inProcess: 'En proceso',
        paid: 'Pagada',
        cancelled: 'Cancelada',
        done: 'Terminada',
        active: 'Activo',
        inactive: 'Inactivo',

        // Invoice
        serial: 'Serie',
        folio: 'Folio',
        invoiceDate: 'Fecha de facturación',
        invoiceNumber: 'Número de factura',
        purchaseOrder: 'Orden de compra',
        amount: 'Importe',
        currency: 'Moneda',
        status: 'Estatus',
        paymentWay: 'Forma de pago',
        paymentMethod: 'Método de pago',
        useCFDI: 'Uso CFDI',
        voucherType: 'Tipo de comprobante',
        uuid: 'UUID',
        issuingTaxId: 'RFC emisor',
        issuingName: 'Nombre emisor',
        recipientTaxId: 'RFC receptor',
        recipientName: 'Nombre receptor',
        estimatedPaymentDate: 'Fecha estimada de pago',

        // Data Table
        recordDisplayed: 'Mostrando START a END de TOTAL_ROWS registro',
        dates: 'Fechas',
        actions: 'Acciones',
        show: 'Mostrar',
        records: 'registros',
        created: 'Fecha de registro',

        // Login
        username: 'Usuario',
        password: 'Contraseña',
        login: 'Iniciar sesión',
        logOut: 'Cerrar sesión',
        notHaveAnAccount: '¿No tienes cuenta?',
        haveAnAccount: '¿Ya tienes cuenta?',
        signUp: 'Regístrate',

        // Supplier
        foreignSupplier: 'Proveedor extranjero',
        tradeName: 'Nombre comercial',
        legalName: 'Razón social',
        capitalRegime: 'Régimen capital',
        taxId: 'RFC',
        industry: 'Giro',
        website: 'Página web',
        bankInformation: 'Datos bancarios',
        constitutiveAct: 'Acta constitutiva',
        taxSituation: 'Constancia de situación fiscal',
        bankCover: 'Carátula bancaria',
        repse: 'REPSE',

        // Address
        street: 'Calle',
        intNumber: 'No. Interior',
        extNumber: 'No. Exterior',
        neighborhood: 'Colonia',
        borough: 'Delegación',
        city: 'Ciudad',
        state: 'Estado',
        country: 'País',
        zipcode: 'Código postal',

        // Contact
        name: 'Nombre',
        jobPosition: 'Puesto',
        jobArea: 'Área de trabajo',
        phone: 'Teléfono',
        extension: 'Extensión',
        cellPhone: 'Celular',
        email: 'Correo',

        // Error messages
        requiredField: 'Campo requerido',
        invalidEmail: 'Coloque un correo electrónico valido',
        incorrectUsernamePassword: 'Usuario y/o contraseña incorrectos',
        rejectedMessage: 'El registro fue rechazado, actualice los datos y envialo nuevamente.',
        noResultsFound: 'No se encontraron resultados',
        msgSendRegistration: '¿Estás seguro de ACTION el registro?',
        selectFile: 'Favor de seleccionar un archivo',
        taxIdDifferent: 'El RFC emisor es diferente al RFC de la cuenta',
        invalidTaxId: 'El RFC receptor no es valido',
        onlyCurrentMonth: 'Solo se pueden subir facturas del mes en curso',
        differentPaymentMethod: 'En facturas con método de pago PPD (Pago en parcialidades o diferido) solo se aceptan con forma de pago 099 (Por definir).',
        invalidVoucherType: 'El tipo de comprobante es inválido',
        invalidFileFormat: 'El formato del archivo no es válido',
        taxIdRegistered: 'El RFC ya se encuentra registrado en el sistema con otro proveedor.',
        emailRegistered: 'La dirección de correo ya se encuentra registrada con otro proveedor',
        tokenRevoked: 'El token fue revocado',
        tokenRedeemed: 'El token fue canjeado previamente',
        NoTokenExist: 'El token no existe',
        invoiceRegistered: 'La factura ya fue registrada.',
        notValueValid: 'Ingrese un valor valido',
        exchangeRate: 'Tipo de cambio',
        inactiveAccount: 'Cuenta inactiva',

        // Success messages
        supplierSentSuccess: 'El registro fue enviado correctamente',
        updateSuccess: 'Se actualizó correctamente',
        invoiceSent: 'Factura enviada',

        // Invoice alerts messages
        pendingPurchaseOrderCode: 'Es necesario validar la orden de compra',
        validPurchaseOrderCode: 'Orden de compra válida',
        invalidPurchaseOrderCode: 'Orden de compra inválida',
        purchaseOrderInvoicedAlert: 'El monto total de la orden de compra ya esta facturado, por este motivo no se permitirá agregar mas facturas.',
        purchaseOrderCurrencyPart1Alert: 'La moneda de la orden de compra',
        purchaseOrderCurrencyPart2Alert: 'es distinta a la moneda de la factura',
        amountExceeded: 'El importe actual facturado más el importe de esta factura excede el importe de la orden de compra',

        // Invoice labels
        purchaseOrderAmountLabel: 'Importe de la Orden de Compra',
        amountInvoicedLabel: 'Importe facturado',
        invoiceAmountLabel: 'Importe de esta factura',
        requiresXmlFile: 'Requiere XML',
        registerInvoice: 'Registrar factura',

        // Alerts
        msgActionInvoice: '¿Estás seguro de ACTION la factura?',

        // Constants
        DEFAULT_ERROR_MESSAGE: 'Ha ocurrido un error. Por favor contacte a soporte.',
        FORBIDDEN_ERROR_MESSAGE: 'No cuentas con autorización para realizar esta acción'
    }
};