import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import vue from '../main';

import { loginUrl, getOauthToken, registerUser, validateSupplierToken, downloadFile } from '@routes';

Vue.use(VueAxios, axios);

let refreshingInProgress = false;
let requestQueue = [];

function processQueue(error) {
    requestQueue.forEach(promise => {
        if (error) {
            promise.reject(error);
        } else {
            promise.resolve();
        }
    });

    requestQueue = [];
}

function refreshToken() {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.set('grant_type', 'refresh_token');
        formData.set('refresh_token', JSON.parse(localStorage.getItem('token')).refreshToken);

        axios.post(getOauthToken(), formData).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

Vue.axios.interceptors.request.use(config => {
    if (config.url === getOauthToken()) {
        config.headers.Authorization = `Basic ${btoa(JSON.parse(localStorage.getItem('token')).clientId + ':' +  JSON.parse(localStorage.getItem('token')).clientSecret)}`;
    } else if (config.url !== loginUrl() && config.url !== registerUser() && config.url !== validateSupplierToken()) {
        config.headers.Authorization = `Bearer ${ JSON.parse(localStorage.getItem('token')).accessToken}`;
    }

    return config;
}, error => { return error; });

Vue.axios.interceptors.response.use(response => response, error => {
    return new Promise((resolve, reject) => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');

            const originalRequest = error.config;

            if (refreshingInProgress) {
                return new Promise((resolve, reject) => {
                    requestQueue.push({ resolve, reject });
                }).then(() => {
                    return Vue.axios.request(originalRequest).then(response => { resolve(response); });
                }).catch(error => {
                    reject(error);
                });
            }

            refreshingInProgress = true;

            refreshToken().then(response => {
                let token = JSON.parse(localStorage.getItem('token'));

                token.accessToken = response.data.access_token;
                token.expiresIn = response.data.expires_in;
                token.refreshToken = response.data.refresh_token;

                localStorage.setItem('token', JSON.stringify(token));

                processQueue();
                return Vue.axios.request(originalRequest).then(response => {
                    refreshingInProgress = false;
                    resolve(response);
                });
            }).catch(error => {
                reject(error);
                location.href = '/';
            });
        } else if (error.response && error.response.status === 400) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            location.href = '/';
            error.reload = true;
            reject(error);
        } else {
            reject(error);
        }
    });
});
