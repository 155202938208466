const baseUrl = process.env.VUE_APP_BASE_URL;
const oauthV2Token = '/oauth/v2/token';
const api = '/api-supplier-portal';

//Modules
const supplier = '/supplier';
const login = '/login';
const currency = '/currency';
const jobArea = '/job-area';
const supplierToken = '/supplier-token';
const invoice = '/invoice';
const purchaseOrder = '/purchase-order';
const company = '/company';
const file = '/file';
const notice = '/notice';

//Actions
const validate = '/validate';
const save = '/save';
const edit = '/edit';
const listSelect2 = '/list-select2';
const list = '/list';
const show = '/show';
const update = '/update';
const download = '/download';
const downloadFormat = '/download-format';
const getUserData = '/get-user-data';

module.exports = {
    loginUrl: () => `${baseUrl}${api}${login}${validate}`,
    getUserData: id => `${baseUrl}${api}${login}/${id}${getUserData}`,
    getOauthToken: () => `${baseUrl}${oauthV2Token}`,
    registerUser: () => `${baseUrl}${api}${supplier}${save}`,
    editSupplier: supplierId => `${baseUrl}${api}${supplier}/${supplierId}${edit}`,
    currencyList: () => `${baseUrl}${api}${currency}${listSelect2}`,
    jobAreaList: () => `${baseUrl}${api}${jobArea}${listSelect2}`,
    validateSupplierToken: () => `${baseUrl}${api}${supplierToken}${validate}`,
    validatePurchaseOrder: () => `${baseUrl}${api}${invoice}${purchaseOrder}${validate}`,
    saveInvoice: () => `${baseUrl}${api}${invoice}${save}`,
    invoiceListUrl: supplierId => `${baseUrl}${api}${invoice}/${supplierId}${list}`,
    purchaseOrderList: supplierId => `${baseUrl}${api}${purchaseOrder}/${supplierId}${list}`,
    invoiceShow: invoiceId => `${baseUrl}${api}${invoice}/${invoiceId}${show}`,
    invoiceUpdate: invoiceId => `${baseUrl}${api}${invoice}/${invoiceId}${update}`,
    getTaxSituation: () => `${baseUrl}${api}${company}/get-tax-situation`,
    downloadFile: id => `${baseUrl}${api}${file}${download}/${id}`,
    downloadPdfFormat: id => `${baseUrl}${api}${purchaseOrder}${downloadFormat}/${id}`,
    getNotices: () => `${baseUrl}${api}${notice}${list}`
};